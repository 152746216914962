<template>
    <div class="identityModifier" ref="identityModifier">
        <autosaveinfo class="autosaveinfo" :data="autoSave" v-if="menuMode.update == true" @save="upt_ident()" />
    
        <div class="flexRow row1">
            <div class="name">
                <label>Nom de la charte</label>
                <input class="charte_name" type="text" placeholder="Ma charte" ref="ident_name" @input="nameChanged"/>
            </div>
        </div>
        <div class="flexRow row2">
            <div>
                <label>Logo</label>
                <EditableImage :editable="true" class="logo" image_type="logo" @change="changeLogo" :src_default="'default_identity'" :src_img="local_current_identity.ident_logo" />
            </div>
            <div>
                <label>Baseline</label>
                <input placeholder="Ma baseline" type="text" ref="baseline" @input="baselineChanged"/>
                <label style="margin-top: 22px">Police d'écriture</label>
                <SelectDropdown class="typoSelect" :choices="font_name_list_selector" :placeholder="font_selector_placeholder" :loading="fonts_loading" @select="select_Identity_Font" />
            </div>
        </div>
        <div class="row3">
            <label>Couleurs</label>
            <div class="flexRow color center">
                <div>
                    <label>Principale</label>
                    <span type="color" :style="{
                  'background-color': local_current_identity.ident_colors[0],
                }" @click="$refs.ident_colors_0.click()"></span>
                    <input ref="ident_colors_0" type="color" :value="local_current_identity.ident_colors[0]" @change="changeColor($event, 0)" />
                    <input type="text" :value="local_current_identity.ident_colors[0]" @change="changeColor($event, 0)" placeholder="#00ADB5" />
                </div>
                <div>
                    <label>Secondaire</label>
                    <span type="color" :style="{
                  'background-color': local_current_identity.ident_colors[1],
                }" @click="$refs.ident_colors_1.click()"></span>
                    <input ref="ident_colors_1" type="color" :value="local_current_identity.ident_colors[1]" @change="changeColor($event, 1)" />
                    <input type="text" :value="local_current_identity.ident_colors[1]" @change="changeColor($event, 1)" placeholder="#3d545c" />
                </div>
                <div>
                    <label>Texte</label>
                    <span type="color" :style="{
                  'background-color': local_current_identity.ident_colors[2],
                }" @click="$refs.ident_colors_2.click()"></span>
                    <input ref="ident_colors_2" type="color" :value="local_current_identity.ident_colors[2]" @change="changeColor($event, 2)" />
                    <input type="text" :value="local_current_identity.ident_colors[2]" @change="changeColor($event, 2)" placeholder="#FFFFFF" />
                </div>
            </div>
        </div>
        <div class="flexRow row_graphic_univers">
            <!-- ANIMATION-->
            <div>
                <label>Intro</label>
                    <AnimationViewer class="card" :autoplay="true" :clickable="true" :animation_id="(local_current_identity.ident_intro != null && local_current_identity.ident_intro != undefined) ? local_current_identity.ident_intro : animation_Id_byType(0)" @click="changeAnimation('ident_intro')" :default_feature_value="{
                ...animation_default_feature_value,
                text: local_current_identity.ident_baseline,
              }" />
            </div>
            <div>
                <label>Titre</label>
                <AnimationViewer class="card" :autoplay="true" :clickable="true" :animation_id="
                local_current_identity.ident_title ||
                animation_Id_byType(1) ||
                null
              " @click="changeAnimation('ident_title')" :default_feature_value="{
                ...animation_default_feature_value,
                text: 'Exemple de question',
              }" />
            </div>
            <div>
                <label>Outro</label>
                <AnimationViewer class="card" :autoplay="true" :clickable="true" :animation_id="
                local_current_identity.ident_outro || animation_Id_byType(2)
              " @click="changeAnimation('ident_outro')" :default_feature_value="{
                ...animation_default_feature_value,
                text: local_current_identity.ident_baseline,
              }" />
            </div>
        </div>
        <div class="flexRow row5">
            <!-- BUTTONS -->
            <button class="button addIdentity" :class="{ desactive: !addButtonActive}" v-if="menuMode.add == true" @click="add_ident()">
            Enregistrer
          </button>
        </div>
    </div>
</template>

<script>
//LIBS
import getColors from "get-image-colors";

// CUSTOMS LIBS
import file_api from "../../../../api/file";
import collect_api from "../../../../api/collect";
import snackbar from "../../../Object/snackbar";
import { displayDate } from "../../../../lib/date";

// COMPONENTS
import EditableImage from "../../../Object/EditableImage";
import SelectDropdown from "../../../Object/SelectDropdown/SelectDropdown";
import AnimationViewer from "../../../Object/animationViewer";
import Autosaveinfo from "../../../Object/AutoSaveInfo.vue";

//STORE
import Vuex from "vuex";
import store from "../../../../store/index";

//REGEX
const regex_id = RegExp("^[a-fA-F0-9]{24}$");

export default {
    name: "IdentityModifier",
    data() {
        return {
            local_current_identity: {
                _id: null,
                ident_name: "",
                ident_colors: [],
                ident_logo: null,
                ident_baseline: "",
                ident_font: null,
                ident_intro: null,
                ident_title: null,
                ident_outro: null,
            },
            show_animation_catalog: false,
            animation_modifier_ref: null,
            animation_default_feature_value: {
                colors: [],
                font: {},
                logo: "",
            },
            font_selector_placeholder: "Police d'écriture",
            uploaded_logo_file: null,
            autoSave: {
                status: 1,
                lastKeyUp: Date,
                lastSave: "",
            },
        };
    },
    store: store,
    components: {
        EditableImage,
        SelectDropdown,
        AnimationViewer,
        Autosaveinfo
    },
    emits: ["show_animation_catalog", "finished"],
    props: [
        "current_identity",
        "menuMode",
        "usr_id",
        "identityModifier_style",
        "newAnimationIdFromCatalog",
    ],
    computed: {
        ...Vuex.mapGetters([
            "font_name_list_selector",
            "animation_Id_byType",
            "font",
            "fonts_loading"
        ]),
        addButtonActive(){
            var emptyField = false
            /*if (
                this.current_identity.ident_name == null ||
                this.current_identity.ident_name == undefined ||
                this.current_identity.ident_name == ""
            ) {
                emptyField = true;
            } else */if (
                this.uploaded_logo_file == null ||
                this.uploaded_logo_file == undefined
            ) {
                emptyField = true;
            } else if (
                this.current_identity.ident_font == null ||
                this.current_identity.ident_font == undefined ||
                this.current_identity.ident_font == ""
            ) {
                emptyField = true;
            } else if (this.current_identity.ident_colors.length != 3) {
                emptyField = true;
            }
            
            if (emptyField == false){
                return true
            } else {
                return false
            }
        }
    },
    mounted() {
        //Setting up current_identity props as local data (to allow modification)
        if (this.current_identity != undefined && this.current_identity != null) {
            this.local_current_identity = this.current_identity;
        }

        //SETTINGS UP INPUT VALUE
        this.$refs.ident_name.value = this.local_current_identity.ident_name
        this.$refs.baseline.value = this.local_current_identity.ident_baseline

        //Verify if ident_intro is not null or undefined
        if (this.local_current_identity.ident_intro == null || this.local_current_identity.ident_intro == undefined){
            if (this.animation_Id_byType(0) != null){
                this.local_current_identity.ident_intro = this.animation_Id_byType(0)
            } else {
                this.fetch_default_animations(0)
                    .then(default_animation_id =>{
                        this.local_current_identity.ident_intro = default_animation_id
                    })
            }
        }

        //Verify if ident_title is not null or undefined
        if (this.local_current_identity.ident_title == null || this.local_current_identity.ident_title == undefined){
            if (this.animation_Id_byType(1) != null){
                this.local_current_identity.ident_intro = this.animation_Id_byType(1)
            } else {
                this.fetch_default_animations(1)
                    .then(default_animation_id =>{
                        this.local_current_identity.ident_title = default_animation_id
                    })
            }
        }

        //Verify if ident_outro is not null or undefined
        if (this.local_current_identity.ident_outro == null || this.local_current_identity.ident_outro == undefined){
            if (this.animation_Id_byType(1) != null){
                this.local_current_identity.ident_intro = this.animation_Id_byType(1)
            } else {
                this.fetch_default_animations(2)
                    .then(default_animation_id =>{
                        this.local_current_identity.ident_outro = default_animation_id
                    })
            }
        }

        //Setting up a default font
        this.init_font_animation_name_list()
            .then(fonts =>{
                if (this.local_current_identity.ident_font == null || this.local_current_identity.ident_font == undefined){
                    this.font_selector_placeholder = fonts[0].font_fName.value;
                    this.local_current_identity.ident_font = fonts[0]._id;

                    //Actualise font in animation
                    this.current_identity_changed(false)
                }
            })

        //Trigger function to update default animation feature
        this.current_identity_changed(false);
    },
    watch: {
        identityModifier_style(style) {
            if (style) {
                this.$refs.identityModifier.removeAttr("style").css(style);
            }
        },
        newAnimationIdFromCatalog(data) {
            if (data && data.anim_id && data.animation_ref) {
                this.local_current_identity[data.animation_ref] = data.anim_id;
                this.upt_ident()
            }
        },
    },
    methods: {
        displayDate,
        ...Vuex.mapActions([
            "init_font_animation_name_list",
            "get_font_animation",
            "fetch_default_animations",
        ]),
        triggerAutoSave(){
            this.autoSave.status = 0;
            this.autoSave.lastKeyUp = new Date();
        },
        //Update animation default feature and font name (was trigger at mounting and in watch of this.current_identity)
        current_identity_changed(mustSave = true) {
            //DEFAULT ANIMATION VALUE
            //Colors
            this.animation_default_feature_value.colors = this.local_current_identity.ident_colors;

            //Logo URL
            if (this.local_current_identity.ident_logo != null) {
                if (regex_id.test(this.local_current_identity.ident_logo)) {
                    //IF LOGO IS AN ID (COME FROM IDENTITY EXISTANT)
                    //Transform media_id of logo in blob
                    file_api
                        .get(this.local_current_identity.ident_logo)
                        .then((bloblUrl) => {
                            this.animation_default_feature_value.logo = bloblUrl;
                        });
                } else {
                    //IF current_identity.ident_logo it's already a blob, just setting up as animation_default_feature_value
                    this.animation_default_feature_value.logo =
                        this.local_current_identity.ident_logo;
                }
            }

            //Search font name (For UI)
            if (this.local_current_identity.ident_font != null) {
                this.get_font_animation(this.local_current_identity.ident_font)
                    .then((font) => {
                        this.font_selector_placeholder = font.font_fName.value;
                        this.animation_default_feature_value.font = font;
                    })
                    .catch((e) => {
                        console.warn("Font not found : ", e);
                        if (this.font_name_list_selector.length > 0) {
                            this.font_selector_placeholder = this.font_name_list_selector[0].name;
                            this.animation_default_feature_value.font = this.font_name_list_selector[0].value;
                        } else {
                            this.font_selector_placeholder = "Police d'écriture";
                        }
                    });
            }

            if (mustSave && mustSave == false){

            } else if (this.menuMode.update == true){
                //AUTO SAVE
                this.triggerAutoSave()
            }
        },
        changeLogo(event) {
            this.uploaded_logo_file = event.target.file;
            this.local_current_identity.ident_logo = event.target.url;

            //If no colors defin, guess it
            //if (this.current_identity.ident_colors.length == 0){
            getColors(event.target.url, event.target.file.type)
                .then((colors) => {
                    colors = colors.map((color) => color.hex());
                    this.local_current_identity.ident_colors = [
                        colors[0],
                        colors[1],
                        colors[2],
                    ];
                    this.current_identity_changed();
                })
                .catch((e) => {
                    console.error("ERROR COLOR : ", e);
                });
            //}

            //IF IT'S AN UPDATE => UPDATE LOGO TO MEDIA DB
            if (this.menuMode == "update") {
                file_api
                    .post(this.uploaded_logo_file)
                    .then((media) => {
                        this.local_current_identity.ident_logo = media._id;

                        //AUTO SAVE
                        this.autoSave.lastKeyUp = new Date();
                        this.autoSave.status = 0;
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        },
        changeColor(event, index) {
            this.local_current_identity.ident_colors[index] = event.target.value;
            this.triggerAutoSave()
        },

        nameChanged(evt){
            this.local_current_identity.ident_name = evt.target.value;
            
            if (this.menuMode.update == true){

                //RUN AUTO SAVE
                this.triggerAutoSave()
            }

        },

        baselineChanged(evt){
            setTimeout(() => {
                if (this.local_current_identity.ident_baseline != evt.target.value){
                    this.local_current_identity.ident_baseline = evt.target.value
                    this.triggerAutoSave()
                }
            }, 2000);
        },

        //Function when font was selected in selector drop down
        select_Identity_Font(choice, featureIndex /*, name*/ ) {
            if (typeof featureIndex == "object" || choice == undefined) {
                return;
            }
            this.local_current_identity.ident_font = choice;
            this.current_identity_changed()
            
        },

        changeAnimation(animation_ref) {
            this.$emit("show_animation_catalog", {
                animation_default_feature_value: this.animation_default_feature_value,
                animation_ref: animation_ref,
            });
        },

        //Create a snakbar
        errorSnackbar(message) {
            snackbar.show({
                text: message,
                pos: "bottom-right",
                showAction: false,
                backgroundColor: snackbar.backgroundColor,
                duration: snackbar.duration,
                textColor: snackbar.colorLevel[0],
            });
        },

        

        //SAVE NEW IDENTITY
        add_ident() {
            //Set props usr_id to identity
            this.local_current_identity.usr_id = this.usr_id
            
            //IF NO IDENT NAME : CREATE IT
            if (
                this.local_current_identity.ident_name == null ||
                this.local_current_identity.ident_name == undefined ||
                this.local_current_identity.ident_name == ""
            ) {
                this.local_current_identity.ident_name = `Charte graphique du ${displayDate(new Date())}`
                this.$refs.ident_name.value = this.local_current_identity.ident_name
            }

            //REQUIRE FIELD VERIFICATION
            if (
                this.uploaded_logo_file == null ||
                this.uploaded_logo_file == undefined
            ) {
                this.errorSnackbar("Veuillez fournir votre logo");
            } else if (
                this.local_current_identity.ident_font == null ||
                this.local_current_identity.ident_font == undefined ||
                this.local_current_identity.ident_font == ""
            ) {
                this.errorSnackbar("Veuillez selectionner votre police d'écriture");
            } else if (this.local_current_identity.ident_colors.length != 3) {
                this.errorSnackbar("Veuillez selectionner vos couleurs");
            } else if (this.local_current_identity.usr_id == undefined || this.local_current_identity.usr_id == null || this.local_current_identity.usr_id == ""){
                console.error('No usr_id')
            } else {
                file_api.post(this.uploaded_logo_file)
                    .then((pic) => {
                        this.local_current_identity.ident_logo = pic._id;

                        //Post new entity
                        var entity = {
                            ...this.current_identity,
                            usr_id: this.usr_id,
                        };
                        return collect_api.add("identities", entity);
                    })
                    .then((identityCreated) => {
                        //Send identity data to parent component
                        this.$emit("finished", identityCreated)

                    })
                    .catch((err) => {
                        console.log(err)
                        this.errorSnackbar("Une erreur est survenu");
                    });
            }
        },

        //UPDATE IDENTITY
        upt_ident() {
            if(this.menuMode.update == true){
                collect_api
                .update("identities", this.local_current_identity)
                .then(() => {

                    this.autoSave.status = 1;
                    this.autoSave.lastSave = new Date()
                    
                })
                .catch((err) => {
                    this.autoSave.status = 2;

                    snackbar.show({
                        text: "Les champs en rouge sont indispensables !",
                        pos: "top-center",
                        showAction: false,
                        backgroundColor: snackbar.backgroundColor,
                        duration: snackbar.duration,
                        textColor: snackbar.colorLevel[0],
                    });
                });
            }
            
        },

        back(){
            this.$emit('finished')
        }
    },
};
</script>


<style src="./style.css" scoped>

</style>