<template>
  <div :class="{ panel: isApanel, notAPanel: !isApanel }" panel-position="0">
    <!-- PANEL ANIMATION MODIFIER-->
    <transition name="panel-slide">
      <div class="panel" panel-position="50" v-if="show_animation_catalog">
        <AnimationModifier
          v-if="show_animation_catalog"
          @finished="animation_modifier_finished"
          :default_feature_value="{
            ...animation_default_feature_value,
            text: 'Exemple de question',
          }"
          :animation_ref="animation_modifier_ref"
        />
      </div>
    </transition>

    <div style="height: 100%; overflow: scroll">
      <!-- TITLE (ARIAN) -->
      <p class="navigation_title" v-if="hide_title != true">
        <a v-if="back_button_title == true" @click="cancel">Retour</a>
        <a v-else @click="cancel">Tableau de bord</a>
        <a> / </a>
        <a> Charte graphique </a>
      </p>

      <!-- NAVIGATION TAB -->
      <navigationTab :changeActiveIndex="navigationTabIndex">
        <h2
          v-bind:class="{ active: menuMode.choice == true }"
          @click="setMenuMode('choice')"
        >
          Mes chartes
        </h2>
        <h2
          v-bind:class="{ active: menuMode.add == true }"
          v-if="!menuMode.update"
          @click="setMenuMode('add')"
        >
          Créer une charte 
        </h2>
        <h2
          v-bind:class="{ active: menuMode.update == true }"
          v-if="menuMode.update"
        >
          Modifier une charte 
        </h2>
      </navigationTab>

      <!-- IDENTITY SELECTION -->
      <div class="cardListContainer" v-if="menuMode.choice == true">
        <div class="cardList center">
          <div v-for="ident in identities" :key="ident" class="card">
            <div class="cardImageBackground">
              <img :src="ident.media" />
            </div>
            <div class="cardImageContainer">
              <img :src="ident.media" />
            </div>
            <div class="card-bottom" style="display: inline-flex">
              <p
                class="card-title"
                @click="choose_identity(ident._id)"
                style="cursor: pointer"
              >
                {{ ident.ident_name }}
              </p>
              <img
                class="delete"
                src="../../../assets/poubelle.png"
                @click="remove_ident(ident._id)"
              />
              <p
                class="button"
                button-type="secondary"
                style="right: auto; left: 0px"
                @click="modify_ident(ident._id)"
              >
                Modifier
              </p>

              <img v-if="usedIdentities.length == 1 && usedIdentities.includes(ident._id)" src="../../../assets/checked_white.png" class="item_check"/>
              <p v-else-if="isApanel == true" class="button" @click="choose_identity(ident._id)">Choisir</p>
            </div>
          </div>
        </div>
      </div>

      <!-- IDENTITY CREATION / UPDATE-->
      <IdentityModifier
        v-if="menuMode.add == true || menuMode.update == true"
        ref="modifyPanel"
        :class="{ modifications: true, center: true, fullWidth: fullWidth }"
        :current_identity="current_identity"
        :menuMode="menuMode"
        :usr_id="usr_id"
        :style="identityModifier_style"
        @show_animation_catalog="handle_event_show_animation_catalog"
        :newAnimationIdFromCatalog="newAnimationIdFromCatalog"
        @finished="identityModifiderFinished"
      />
    </div>
  </div>
</template>

<script>
// LIBS
import axios from "axios";
import emitter from "tiny-emitter/instance";
import $ from "jquery";

// CUSTOMS LIBS
import collect_api from "../../../api/collect";
import snackbar from "../../Object/snackbar";

//INTERNALS COMPONENT
import IdentityModifier from "./IdentityModifier/IdentityModifier";

// COMPONENTS
import navigationTab from "../../Object/navigation-tab/navigation-tab";
import AnimationModifier from "../../Object/animationModifier/animationModifier.vue";

//STORE
import Vuex from "vuex";
import store from "./../../../store/index";

export default {
  name: "IdentityChoice",
  props: {
    vdo_ids: {default: []},
    usr_id: {},
    hide_title: {
      default: false,
    },
    back_button_title: {},
    isApanel: {
      default: true,
    },
  },
  store: store,
  components: {
    navigationTab,
    IdentityModifier,
    AnimationModifier,
  },
  emits: ["finished"],

  data: function () {
    return {
      current_identity: {
        _id: null,
        ident_name: "",
        ident_colors: [],
        ident_logo: null,
        ident_baseline: "",
        ident_font: null,
        ident_intro: null,
        ident_title: null,
        ident_outro: null,
      },

      identities: [],
      menuMode: {
        choice: true,
        add: false,
        update: false,
      },

      addButtonEnable: false,
      show_animation_catalog: false,
      identityModifier_style: undefined,
      newAnimationIdFromCatalog: undefined
    };
  },
  computed: {
    navigationTabIndex() {
      switch (this.setMenuMode()) {
        case "choice":
          return 0;
        case "add":
          return 1;
        case "update":
          return 1;
        default:
          return undefined;
      }
    },
    usedIdentities() {
      //Get identity previously selected for this video in store
      return this.getVideos().filter(vdo => this.vdo_ids.includes(vdo._id)).map(vdoSelected =>{
        return vdoSelected.ident_id
      })
    },
    fullWidth() {
      var panel_pos = 100;
      if ($(".panel .identity_pannel").attr("panel-position") != undefined) {
        panel_pos = Number($(".panel .identity_pannel").attr("panel-position"));
      }

      if (panel_pos <= 50) {
        return true;
      } else {
        return false;
      }
    },
    ...Vuex.mapGetters(["getVideos"])
  },
  mounted: function () {
    // Set click location to app.vue
    setTimeout(() => {
      emitter.emit("click_location", "panel1");
    }, 500);

    this.fetch_identities();
  },
  methods: {
    ...Vuex.mapActions(["updateVideo", "unsetField_video"]),

    //FETCH IDENTITY LIST
    fetch_identities() {
      collect_api
        .get("/identities", { usr_id: this.usr_id, media: true })
        .then((idents) => {
          this.identities = idents;
          if (this.identities.length == 0) {
            this.setMenuMode("add");
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },

    modify_ident(id) {
      this.current_identity = this.identities.filter((identity) => {
        if (identity._id == id) {
          return true;
        } else {
          return false;
        }
      })[0];
      this.setMenuMode("update");
    },

    remove_ident(id) {
      axios.delete("/identities/" + id).then(() => {
        this.identities = this.identities.filter((obj) => obj._id != id);
        this.unsetField_video({ field: "ident_id", value: id });
      });
      snackbar.show({
        text: "Chartre graphique supprimée",
        pos: "top-center",
        showAction: false,
        backgroundColor: snackbar.backgroundColor,
        duration: snackbar.duration,
        textColor: snackbar.colorLevel["success"],
      });
    },

    cancel() {
      this.$emit("finished");
    },

    setMenuMode(mode) {
      if (mode != undefined) {
        switch (mode) {
          case "choice":
            this.reset_form();
            this.menuMode.choice = true;
            this.menuMode.add = false;
            this.menuMode.update = false;
            this.fetch_identities();
            break;

          case "add":
            this.reset_form();
            this.menuMode.choice = false;
            this.menuMode.add = true;
            this.menuMode.update = false;
            break;

          case "update":
            this.menuMode.choice = false;
            this.menuMode.add = false;
            this.menuMode.update = true;
            break;
        }
      }
      if (this.menuMode.choice == true) {
        return "choice";
      } else if (this.menuMode.add == true) {
        return "add";
      } else if (this.menuMode.update == true) {
        return "update";
      }
    },

    reset_form() {
      this.current_identity = {
        _id: null,
        ident_name: "",
        ident_colors: [],
        ident_logo: null,
        ident_baseline: "",
        ident_font: null,
        ident_intro: null,
        ident_title: null,
        ident_outro: null,
      };
      this.uploaded_logo_file = undefined;
    },

    choose_identity(id) {
      var index = this.identities.findIndex((obj) => {
        return obj._id == id;
      });
      this.updateVideo({
        update: {
          ids: this.vdo_ids,
          ident_id: id,
        },
        collect_objs: {
          identity: this.identities[index],
        },
      }).then((res) => {
        this.$emit("finished", {
          identity: this.identities[index],
        });
      });
    },

    handle_event_show_animation_catalog(data) {
      this.animation_default_feature_value =
        data.animation_default_feature_value;
      this.animation_modifier_ref = data.animation_ref;

      //When Animation modification appear, set modify panel to width 40%

      var style = {
        width: "40%",
      };

      //If panel-position of identity pannel is less than 50 (fullWidth == true), shift to left the pannel content
      if (this.fullWidth) {
        style["margin-left"] = "5%";
      } else {
        style.transform = "translateX(-20%)";
      }
      this.identityModifier_style = style;
      this.show_animation_catalog = true;
    },

    animation_modifier_finished(animation_id, animation_ref) {
      this.show_animation_catalog = false;
      this.identityModifier_style = {
        width: "var(--modifications_width)",
        transform: "translateX(0%)",
        "margin-left": "auto",
      };
      this.newAnimationIdFromCatalog = {
        anim_id: animation_id,
        animation_ref: animation_ref,
      };
    },

    identityModifiderFinished(identity_data) {
      if (identity_data != undefined) {
        //SAVE NEW ENTITY OF IDENTITY MODIFICATION TO ENTITIES LIST
        var index = this.identities.findIndex((obj) => {
          return obj._id == identity_data._id;
        });

        if (index != -1) {
          this.identities[index] = identity_data;
        } else {
          this.identities.push(identity_data);
        }

        this.choose_identity(identity_data._id);
      }
  
      this.setMenuMode("choice");
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped>
</style>
